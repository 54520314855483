module directives {
    export module applicationcore {
        interface IDropdownDirective extends ng.IScope {
            list: Array<interfaces.applicationcore.IDropdownModel>,
            filterList: Array<interfaces.applicationcore.IDropdownModel>,
            appendToBody: boolean,
            query(searchText: string, pageinationSearch: false, open: boolean): void,
            loadData({ searchText }): Promise<Array<interfaces.applicationcore.IDropdownModel>>,
            ngChange(any: any): void,
            change(model: interfaces.applicationcore.IDropdownModel): void,
            ngModel: interfaces.applicationcore.IDropdownModel,
            refreshDelay: number,
            selectedItem: {
                selected: interfaces.applicationcore.IDropdownModel
            }
        }

        export class dropdownDirective implements ng.IDirective {
            template = `<p class="input-group-sm has-feedback" ng-class="{'has-error': form[name].$invalid || (!ngModel.Id && required)}">
                        <ui-select spinner-enabled="true"
                        ng-model="selectedItem.selected"
                        ng-disabled="ngDisabled"
                        name="{{name}}"
                        on-select="change($item)"
                        append-to-body="appendToBody"
                        search-enabled="searchEnabled"
                        refreshing="refreshing"
                        ng-required="required"
                                       theme="bootstrap"
                                       reset-search-input="true"
                                       append-to-body="true"
                        uib-popover="{{$select.selected.Description}}" popover-enable="$select.selected.Display.indexOf('...') > -1" popover-trigger="'mouseenter'" popover-append-to-body="true">
                              <ui-select-match title="{{$select.selected.Display}}" allow-clear="{{!required}}" placeholder="Search...">{{$select.selected.Display}}</ui-select-match>
                              <ui-select-choices repeat="item in filterList track by $index" refresh="query($select.search, paginationSearch, $select.open)" refresh-delay="refreshDelay">
                                <span uib-popover="{{item.Description}}" popover-enable="item.Display.indexOf('...') > -1" popover-trigger="'mouseenter'" popover-append-to-body="true">{{item.Display}}</span>
                              </ui-select-choices>
                            </ui-select>
                        </p>`;
            scope = {
                ngModel: "=",
                ngChange: "&",
                ngDisabled: "=",
                loadData: "&",
                required: "=",
                form: "=",
                name: '@',
                list: "=?",
                appendToBody: "=?",
                refreshDelay: "=?",
                paginationSearch: "=?",
                searchEnabled: "=?"
            };

            constructor(public $q: ng.IQService, private generalService: interfaces.applicationcore.IGeneralService, private $timeout: ng.ITimeoutService) {

            }

            link = ($scope: IDropdownDirective, $element: ng.IAugmentedJQuery) => {
                $scope.$watch("ngModel", (newValue: interfaces.applicationcore.IDropdownModel, oldValue: interfaces.applicationcore.IDropdownModel) => {
                    $scope.selectedItem = {
                        selected: newValue
                    };
                }, true);

                $scope.refreshDelay = $scope.refreshDelay || 20;
                $scope.list = [];
                $scope.filterList = [];
                $scope.query = (searchText: string, paginationSearch: boolean, open: boolean) => {
                    searchText = searchText || '';
                    paginationSearch = paginationSearch || false;
                    searchText = searchText.toLocaleLowerCase(); //Convert to lower case to make case insensitive search

                    $scope.list = $scope.list || [];
                    $scope.filterList = [];

                    if(!open) {
                        return;
                    }

                    if (paginationSearch && angular.isDefined($scope.loadData)) {
                        return $scope.loadData({ searchText: searchText }).then((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                            if (data.length === 0) {
                                $scope.filterList = [];
                            } else {
                                $scope.list = data;
                                $scope.query(searchText, false, open);
                            }
                        });
                    } else if (angular.isDefined($scope.loadData)) {
                        if ($scope.list.length > 0) {
                            $scope.filterList = _.filter($scope.list, (n: interfaces.applicationcore.IDropdownModel) => {
                                return (n.Display.toLocaleLowerCase().indexOf(searchText) >= 0 || searchText.length === 0);
                            });
                        } else {
                            return $scope.loadData({ searchText: searchText }).then((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                                if (data.length === 0) {
                                    $scope.filterList = [];
                                } else {
                                    $scope.list = data;
                                    $scope.query(searchText, false, open);
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                });
                        }
                    } else {
                        $scope.filterList = [];
                    }
                }

                $scope.change = (model) => {
                    this.$timeout(() => {
                        if (model && $scope.ngModel && model.Id !== $scope.ngModel.Id) {
                            $scope.ngModel = model;
                            $scope.ngChange({ model: model });
                        } else if (model) {
                            $scope.ngModel = model;
                            $scope.ngChange({ model: model });
                        } else if (!model || model === null) {
                            $scope.ngModel = undefined;
                            $scope.ngChange({ model: undefined });
                        }
                    });
                };
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($q, generalService, $timeout) => new dropdownDirective($q, generalService, $timeout);
                directive.$inject = ['$q', "generalService", "$timeout"];

                return directive;
            }
        }

        export class uiSelectWrap implements ng.IDirective {

            constructor(private $document: ng.IDocumentService, private uiGridEditConstants: uiGrid.edit.IUiGridEditConstants, private $timeout: ng.ITimeoutService) {

            }

            link = (scope, element: angular.IRootElementService, attrs, ngModelController: ng.IController, $select) => {
                this.$document.on('click', (evt) => {
                    if (($(evt.target).closest('.ui-select-container')).length === 0) {
                        scope.$emit(this.uiGridEditConstants.events.END_CELL_EDIT);
                        this.$document.off('click');
                    }
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($document, uiGridEditConstants, $timeout) => new uiSelectWrap($document, uiGridEditConstants, $timeout);
                directive.$inject = ['$document', 'uiGridEditConstants', '$timeout'];

                return directive;
            }
        }
        angular.module("app").directive("gtsDropdown", dropdownDirective.factory());
        angular.module("app").directive("uiSelectWrap", uiSelectWrap.factory());
    }
}